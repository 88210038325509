import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "./css/App.css";
import "./css/index.css";
import "./css/Nav.css";
import AboutMe from "./Pages/AboutMe";
import Home from "./Pages/Home";
import Skills from "./Pages/Skills";
import Projets from "./Pages/Projets";
import Contact from "./Pages/Contact";
library.add(faMoon, faLinkedin, faGithub);

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutMe" element={<AboutMe />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projets" element={<Projets />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
