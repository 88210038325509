import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../css/App.css";
import "../css/index.css";
import "../css/Nav.css";
import "../css/AboutMe.css";
import logo from "../Fichiers/logo.png";
import photo from "../Fichiers/Photo.png";
import CV from "../Fichiers/CV Nikola Legay.pdf";
library.add(faMoon, faLinkedin, faGithub);

const AboutMe = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = CV;
    link.download = "CV Nikola Legay.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const darkbtn = document.getElementById("darkbtn");
    const body = document.body;
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";

    document.documentElement.style.overflow = "hidden";

    if (isDarkMode) {
      body.classList.add("dark-mode");
      darkbtn.checked = true;
    }

    darkbtn.addEventListener("change", () => {
      if (darkbtn.checked) {
        body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "disabled");
      }
    });

    window.addEventListener("load", () => {
      const moonIcon = document.querySelector(".moon-icon");
      moonIcon.style.opacity = "1";
      moonIcon.style.transition = "opacity 1s ease forwards";
    });
  }, []);

  return (
    <div className="App">
      <header className="container">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="logo-panda" />
            </a>
          </div>
          <input type="checkbox" id="click" />

          <label
            htmlFor="click"
            className="mainicon"
            onClick={toggleMobileMenu}
          >
            <div className="menu">
              <i className="bx bx-menu"></i>
            </div>
          </label>
          <span className="bars" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </span>
          <ul className={`navList ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <a href="/" style={{ "--navAni": 1 }}>
                Accueil
              </a>
            </li>
            <li>
              <a href="/aboutMe" className="active" style={{ "--navAni": 2 }}>
                À propos de moi
              </a>
            </li>
            <li>
              <a href="/skills" style={{ "--navAni": 3 }}>
                Skills
              </a>
            </li>
            <li>
              <a href="/projets" style={{ "--navAni": 4 }}>
                Projets
              </a>
            </li>
            <li>
              <a href="/contact" style={{ "--navAni": 5 }}>
                Contact
              </a>
            </li>
          </ul>
          <label className="mode">
            <input type="checkbox" id="darkbtn" />
            <span className="moon-icon">
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </label>
        </div>
      </header>

      <section className="container">
        <div className="main">
          <div className="social">
            {/* <a
              href="#"
              className={`social-btn linkedin-btn`}
              style={{ "--socialAni": 1 }}
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </a>
            <a
              href="#"
              className={`social-btn github-btn`}
              style={{ "--socialAni": 2 }}
            >
              <FontAwesomeIcon icon={["fab", "github"]} />
            </a> */}
          </div>

          <div className="img-secAboutMe">
            <div className="imagesAboutMe"></div>
            <img src={photo} alt="" className="img-wAboutMe" />
          </div>
          <div className="txtAbout">
            <div className="detailAbout">
              <h3 className="underline-gradient1">
                Nikola Legay - Développeur Web Passionné
              </h3>
              <p className="texte">
                Bonjour ! Je m'appelle Nikola Legay, je suis un développeur web
                passionné basé à Yville Sur Seine. Né à Rouen le 30 janvier
                2003, je suis titulaire d'un bac STI2D qui m'a initié au monde
                de la technologie, mais c'est mon parcours post-bac qui a
                véritablement façonné ma passion pour le développement web. En
                plus de mon parcours académique, je suis fier de posséder les
                permis A et B, ce qui me confère une mobilité accrue et facilite
                mes déplacements pour poursuivre ma passion.
                <br></br>
                <br></br>
                Après avoir décroché mon bac, j'ai poursuivi mes études avec un
                BTS SIO option SLAM (Solutions Logicielles et Applications
                Métiers). Durant cette formation, j'ai eu l'opportunité de
                réaliser deux stages enrichissants. Le premier chez Soget au
                Havre, où j'ai exercé en tant que développeur Front-end, et le
                second chez CMA Normandie à Rouen en tant que développeur Full
                Stack. Ces expériences m'ont permis de travailler en équipe, de
                développer des interfaces web, de résoudre des bugs, et
                d'acquérir des compétences essentielles en développement.
                <br></br>
                <br></br>
                Mon parcours m'a conduit à découvrir ma véritable passion : le
                développement web. Aujourd'hui, je suis à la recherche d'une
                alternance de 24 mois pour suivre la formation de développeur
                d'application - JavaScript React sur OpenClassrooms. Cette
                formation, avec son rythme de 4 jours en entreprise et 1 jour en
                formation par semaine, est essentielle pour la poursuite de mon
                cursus.
                <br></br>
                <br></br>
              </p>

              <h3 className="underline-gradient2">Compétences clés :</h3>

              <p className="texte">
                - Langages maîtrisés : C++, C#, Java, PHP, HTML, CSS, SQL. En
                cours d'apprentissage de React et JavaScript.
                <br></br>- Outils maîtrisés : GIT, Visual Studio, Visual Studio
                Code, Android Studio, Qt Creator, MySQL, phpMyAdmin, Photoshop,
                Wismical, Figma.
                <br></br>- Méthodes de travail : Agile, Kanban, utilisant des
                outils tels que Jira, Trello, Gantt.
                <br></br>
                <br></br>
                En dehors du monde du code, mes passions incluent le dessin, la
                moto, et bien sûr, le développement web. Mon entourage me décrit
                comme une personne calme, agréable, organisée, rigoureuse, et
                travailleuse, dotée d'un très bon sens logique.
                <br></br>
                <br></br>
                Mon portfolio, réalisé en JavaScript React, témoigne de mon
                engagement et de ma créativité dans le monde du développement
                web. Je suis enthousiaste à l'idée de mettre en pratique mes
                compétences et d'apprendre continuellement dans un environnement
                professionnel dynamique.
                <br></br>
                <br></br>
                Si vous êtes à la recherche d'un développeur web passionné et
                motivé, n'hésitez pas à me contacter. Je suis prêt à relever de
                nouveaux défis et à contribuer au succès de votre équipe.
                <br></br>
                <br></br>
              </p>
            </div>
            <div className="detail">
              <div className="btncv-socialAboutMe">
                <div className="btn btnCvAboutMe">
                  <button onClick={handleDownload}>Download CV</button>
                </div>
                <div className="social">
                  <a
                    href="https://www.linkedin.com/in/nikola-legay-090b02283/"
                    target="_blank"
                    className={`social-btn linkedin-btn`}
                    style={{ "--socialAni": 1 }}
                  >
                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  </a>
                  <a
                    href="https://github.com/l-nikola"
                    target="_blank"
                    className={`social-btn github-btn`}
                    style={{ "--socialAni": 2 }}
                  >
                    <FontAwesomeIcon icon={["fab", "github"]} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutMe;
