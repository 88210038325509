import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../css/App.css";
import "../css/index.css";
import "../css/Nav.css";
import "../css/Contact.css";
import photo from "../Fichiers/Photo.png";
import logo from "../Fichiers/logo.png";
library.add(faMoon, faLinkedin, faGithub);

function Contact() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const form = useRef();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dkph8hu",
        "template_3vylkvv",
        form.current,
        "ONTELmZFbqUYnDH64"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    const darkbtn = document.getElementById("darkbtn");
    const body = document.body;
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";
    const isMobile = window.innerWidth <= 767;
    const isTablette = window.innerWidth <= 1024;

    // Désactive le défilement uniquement sur les appareils non mobiles
    if (!isMobile) {
      document.documentElement.style.overflow = "hidden";
    }

    if (!isTablette) {
      document.documentElement.style.overflow = "hidden";
    }

    if (isDarkMode) {
      body.classList.add("dark-mode");
      darkbtn.checked = true;
    }

    darkbtn.addEventListener("change", () => {
      if (darkbtn.checked) {
        body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "disabled");
      }
    });

    window.addEventListener("load", () => {
      const moonIcon = document.querySelector(".moon-icon");
      moonIcon.style.opacity = "1";
      moonIcon.style.transition = "opacity 1s ease forwards";

      // Réactive le défilement après le chargement de la page
      if (isMobile) {
        document.documentElement.style.overflow = "auto";
      }

      if (isTablette) {
        document.documentElement.style.overflow = "auto";
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="container">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="logo-panda" />
            </a>
          </div>
          <input type="checkbox" id="click" />

          <label
            htmlFor="click"
            className="mainicon"
            onClick={toggleMobileMenu}
          >
            <div className="menu">
              <i className="bx bx-menu"></i>
            </div>
          </label>
          <span className="bars" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </span>
          <ul className={`navList ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <a href="/" style={{ "--navAni": 1 }}>
                Accueil
              </a>
            </li>
            <li>
              <a href="/aboutMe" style={{ "--navAni": 2 }}>
                À propos de moi
              </a>
            </li>
            <li>
              <a href="/skills" style={{ "--navAni": 3 }}>
                Skills
              </a>
            </li>
            <li>
              <a href="/projets" style={{ "--navAni": 4 }}>
                Projets
              </a>
            </li>
            <li>
              <a href="/contact" className="active" style={{ "--navAni": 5 }}>
                Contact
              </a>
            </li>
          </ul>
          <label className="mode">
            <input type="checkbox" id="darkbtn" />
            <span className="moon-icon">
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </label>
        </div>
      </header>

      <section className="container">
        <div className="main">
          <div className="social">{/* Espace */}</div>
          <div className="img-sec">
            <div className="imagesAbout"></div>
            <img src={photo} alt="" className="img-wAbout" />
          </div>
          <div className="txtContact">
            <div className="coordContact">
              <div className="txtCoordContact">
                <div className="coord">
                  <h3>Contactez moi</h3>
                  <br></br>
                  <br></br>
                  1801 Route des Sablons
                  <br></br>
                  Yville sur Seine, 76530
                  <br></br>
                  France
                  <br></br>
                  <br></br>
                  legay.nikola.pro@gmail.com
                  <br></br>
                  <br></br>
                  06 30 14 56 82
                  <br></br>
                </div>
                <div className="social socialContact">
                  <a
                    href="https://www.linkedin.com/in/nikola-legay-090b02283/"
                    target="_blank"
                    className={`social-btnContact linkedin-btn`}
                    style={{ "--socialAni": 1 }}
                  >
                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  </a>
                  <a
                    href="https://github.com/l-nikola"
                    target="_blank"
                    className={`social-btnContact github-btn`}
                    style={{ "--socialAni": 2 }}
                  >
                    <FontAwesomeIcon icon={["fab", "github"]} />
                  </a>
                </div>
              </div>
            </div>

            <div className="formulaireContact">
              <h3 className="titre">Envoyez moi un message</h3>
              {isFormSubmitted ? (
                <p className="form-submitted-message">
                  Merci ! Votre formulaire a été envoyé avec succès.
                </p>
              ) : (
                <form className="form" ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                    <label>Nom Prénom* :</label>
                    <input type="text" name="name" required />
                  </div>

                  <div className="form-group">
                    <label>Email* :</label>
                    <input type="email" name="email" required />
                  </div>

                  <div className="form-group">
                    <label>Lien :</label>
                    <input name="lien" />
                  </div>

                  <div className="form-group-message">
                    <label>Message* :</label>
                    <textarea name="message" required />
                  </div>

                  <div className="detail">
                    <div className="btn">
                      <button type="submit">Envoyer</button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
