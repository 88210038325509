import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../css/App.css";
import "../css/index.css";
import "../css/Skills.css";
import logo from "../Fichiers/logo.png";
import cplusplus from "../Fichiers/logos/langages/c++-logo.png";
import csharp from "../Fichiers/logos/langages/csharp-logo.png";
import css from "../Fichiers/logos/langages/css-logo.png";
import html from "../Fichiers/logos/langages/html-logo.png";
import java from "../Fichiers/logos/langages/java-logo.png";
import js from "../Fichiers/logos/langages/js-logo.png";
import php from "../Fichiers/logos/langages/php-logo.png";
import react from "../Fichiers/logos/langages/react-logo.png";
import sql from "../Fichiers/logos/langages/sql-logo.png";
import android from "../Fichiers/logos/outils/android-logo.png";
import figma from "../Fichiers/logos/outils/figma-logo.png";
import git from "../Fichiers/logos/outils/git-logo.png";
import mysql from "../Fichiers/logos/outils/mysql-logo.png";
import phpadmin from "../Fichiers/logos/outils/phpadmin-logo.png";
import qt from "../Fichiers/logos/outils/qt-logo.png";
import vs from "../Fichiers/logos/outils/vs-logo.png";
import vscode from "../Fichiers/logos/outils/vscode-logo.png";
import whimsical from "../Fichiers/logos/outils/whimsical-logo.png";
library.add(faMoon, faLinkedin, faGithub);

const Skills = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const cppCard = document.getElementById("cppCard");
    const cppCardFront = cppCard.querySelector(".front");

    cppCard.addEventListener("mouseover", () => {
      cppCardFront.style.transform = "rotateY(-180deg)";
    });

    cppCard.addEventListener("mouseout", () => {
      cppCardFront.style.transform = "rotateY(0deg)";
    });
  }, []);

  useEffect(() => {
    const darkbtn = document.getElementById("darkbtn");
    const body = document.body;
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";

    if (isDarkMode) {
      body.classList.add("dark-mode");
      darkbtn.checked = true;
    }

    darkbtn.addEventListener("change", () => {
      if (darkbtn.checked) {
        body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "disabled");
      }
    });

    window.addEventListener("load", () => {
      const moonIcon = document.querySelector(".moon-icon");
      moonIcon.style.opacity = "1";
      moonIcon.style.transition = "opacity 1s ease forwards";
    });
  }, []);

  return (
    <div className="App">
      <header className="container">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="logo-panda" />
            </a>
          </div>
          <input type="checkbox" id="click" />

          <label
            htmlFor="click"
            className="mainicon"
            onClick={toggleMobileMenu}
          >
            <div className="menu">
              <i className="bx bx-menu"></i>
            </div>
          </label>
          <span className="bars" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </span>
          <ul className={`navList ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <a href="/" style={{ "--navAni": 1 }}>
                Accueil
              </a>
            </li>
            <li>
              <a href="/aboutMe" style={{ "--navAni": 2 }}>
                À propos de moi
              </a>
            </li>
            <li>
              <a href="/skills" className="active" style={{ "--navAni": 3 }}>
                Skills
              </a>
            </li>
            <li>
              <a href="/projets" style={{ "--navAni": 4 }}>
                Projets
              </a>
            </li>
            <li>
              <a href="/contact" style={{ "--navAni": 5 }}>
                Contact
              </a>
            </li>
          </ul>
          <label className="mode">
            <input type="checkbox" id="darkbtn" />
            <span className="moon-icon">
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </label>
        </div>
      </header>

      <section className="container">
        <div className="page">
          <div className="langages">
            <div className="titreSkills">
              <h2 className="underline-skill1">Langages maîtrisés :</h2>
              <p className="txtPC">
                Passez votre curseur sur les logos pour plus de details.
              </p>
              <p className="txtMobile">
                Cliquez sur les logos pour plus de details.
              </p>
            </div>

            <div className="cols">
              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${html})` }}
                  >
                    <div className="inner innerhtml">
                      <p className="lang langhtml">HTML</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxthtml">
                      <p>
                        Ma maîtrise du HTML est attestée par mon portfolio, où
                        vous vous trouvez actuellement. Pendant mon BTS SIO,
                        j'ai également réalisé de nombreux travaux pratiques
                        pour renforcer mes compétences. Restez à l'affût, car de
                        futurs projets seront réalisés avec ce langage. Suivez
                        l'évolution sur mon GitHub :{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=html&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${css})` }}
                  >
                    <div className="inner">
                      <p className="lang">CSS</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtcss">
                      <p>
                        Mon expertise en CSS s'illustre pleinement dans mon
                        portfolio, où le design prend vie. Pendant mon cursus en
                        BTS SIO, j'ai également accompli divers travaux
                        pratiques pour perfectionner mes compétences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${js})` }}
                  >
                    <div className="inner innerjs">
                      <p className="lang langjs">JavaScript</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtjs">
                      <p>
                        En pleine découverte de JavaScript, je m'immerge dans
                        les rouages de ce langage dynamique et passionnant. Mon
                        portfolio, que vous explorez actuellement, est le fruit
                        de mes efforts d'apprentissage. De futurs projets sont à
                        venir sur cette{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=javascript&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          page <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col" id="cppCard">
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${cplusplus})` }}
                  >
                    <div className="inner">
                      <p className="lang">C++</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtcplusplus">
                      <p>
                        Grâce au projet captivant "AP2-Easybee" réalisé pour mes
                        épreuves de BTS SIO, je maîtrise le C++. Explorez le
                        résultat sur mon GitHub :{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=c%2B%2B&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${csharp})` }}
                  >
                    <div className="inner">
                      <p className="lang">C#</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtcsharp">
                      <p>
                        J'ai acquis une solide maîtrise du C# au cours de ma
                        première année de BTS SIO. Découvrez une petite partie
                        de mes travaux pratiques sur GitHub :{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=c%23&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${react})` }}
                  >
                    <div className="inner innerreact">
                      <p className="lang langreact">React</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtreact">
                      <p>
                        En plein apprentissage de React, mon portfolio reflète
                        mes avancées dans l'utilisation de ce framework.
                        Explorez mes futurs projets pour découvrir ma passion
                        grandissante pour le développement web réactif en
                        cliquant{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=javascript&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${java})` }}
                  >
                    <div className="inner">
                      <p className="lang">Java</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtjava">
                      <p>
                        Suite au projet stimulant "AP4-Easybee" réalisé pour mes
                        épreuves de BTS SIO, j'ai acquis des bases solides en
                        Java. Découvrez le résultat sur mon GitHub :{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=java&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${php})` }}
                  >
                    <div className="inner">
                      <p className="lang">PHP</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtphp">
                      <p>
                        Avec plusieurs travaux pratiques réalisés et le projet
                        "AP4-Easybee" conçu pour mes épreuves de BTS SIO, j'ai
                        acquis des bases solides en PHP. Découvrez ce projet sur
                        mon GitHub:{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=&type=&language=java&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${sql})` }}
                  >
                    <div className="inner">
                      <p className="lang">SQL</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtsql">
                      <p>
                        Pour mes projets AP2-Easybee et AP4-Easybee, créés lors
                        de mes épreuves de BTS SIO, le langage SQL a été utilisé
                        pour créer des bases de données et à faire appel à
                        elles. En parallèle, j'ai également réalisé de nombreux
                        travaux pratiques utilisant SQL, complétant ainsi mon
                        expérience dans ce domaine. Découvrez ces projets sur
                        mon GitHub :{" "}
                        <a
                          href="https://github.com/l-nikola?tab=repositories&q=Easybee+&type=&language=&sort="
                          target="_blank"
                          className="btnProjets"
                        >
                          ici <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="outils">
            <h2 className="underline-skill2">Outils maîtrisés :</h2>

            <div className="cols">
              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${vs})` }}
                  >
                    <div className="inner innervs">
                      <p className="lang langvs">Visual Studio</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtvs">
                      <p>
                        Grâce à ma première année de BTS SIO, j'ai acquis une
                        maîtrise solide de Visual Studio, notamment avec le
                        langage C#. Mes travaux pratiques ont renforcé ma
                        compétence dans l'utilisation de cet environnement de
                        développement intégré.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${vscode})` }}
                  >
                    <div className="inner innervs">
                      <p className="lang langvs">Visual Studio Code</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtvscode">
                      <p>
                        Visual Studio Code est devenu mon logiciel de
                        développement préféré, et j'ai perfectionné ma maîtrise
                        de cet outil polyvalent non seulement pendant mon BTS
                        SIO, mais aussi au cours de mes stages.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col" id="cppCard">
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${android})` }}
                  >
                    <div className="inner innerandroid">
                      <p className="lang langandroid">Android Studio</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtandroid">
                      <p>
                        Au cours de mon BTS SIO, j'ai dû maîtriser Android
                        Studio pour divers travaux pratiques. Cependant, c'est
                        avec le projet AP4-Easybee, pour mes épreuves, que j'ai
                        perfectionné mes compétences, démontrant ainsi mon
                        savoir-faire avec Android Studio.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${figma})` }}
                  >
                    <div className="inner innerfigma">
                      <p className="lang langfigma">Figma</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtfigma">
                      <p>
                        Pour mon portfolio, j'ai utilisé Figma pour concevoir
                        les maquettes que vous parcourez actuellement. Attiré
                        par sa praticité et son intérêt, je prévois
                        d'approfondir mes connaissances dans ce logiciel, séduit
                        par ses possibilités fascinantes en matière de design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${whimsical})` }}
                  >
                    <div className="inner innerwhimsical">
                      <p className="lang langwhimsical">Whimsical</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtwhimsical">
                      <p>
                        Avec Whimsical, j'ai concrétisé les maquettes de mes
                        projets AP2-Easybee et AP4-Easybee. Bien que je n'aie
                        pas prévu d'approfondir mes connaissances dans ce
                        logiciel vu que , son utilité indéniable a contribué à
                        la réussite visuelle de mes projets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card"
                    style={{ backgroundImage: `url(${git})` }}
                  >
                    <div className="inner">
                      <p className="lang">Git</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtgit">
                      <p>
                        Actuellement en cours d'apprentissage de Git, je
                        l'intègre progressivement dans mes projets en cours.
                        Cette expérience enrichissante m'offre une compréhension
                        plus approfondie de la gestion de version, renforçant
                        ainsi mes compétences en développement collaboratif.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card bot"
                    style={{ backgroundImage: `url(${mysql})` }}
                  >
                    <div className="inner innermysql">
                      <p className="lang langmysql">MySQL</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtmysql">
                      <p>
                        Pendant mon BTS SIO, j'ai acquis une maîtrise de MySQL,
                        un outil essentiel en gestion de bases de données. Cette
                        compétence s'est avérée particulièrement bénéfique dans
                        la réalisation de mes projets AP2-Easybee et
                        AP4-Easybee, où j'ai pu appliquer concrètement mes
                        connaissances en conception et manipulation de bases de
                        données.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card bot"
                    style={{ backgroundImage: `url(${phpadmin})` }}
                  >
                    <div className="inner innerphpadmin">
                      <p className="lang langphpadmin">phpMyAdmin</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtphpadmin">
                      <p>
                        Grâce à mon BTS SIO, j'ai maîtrisé l'utilisation de
                        phpMyAdmin, une interface pratique pour gérer les bases
                        de données MySQL. Cette compétence a joué un rôle
                        crucial dans le développement de mes projets AP2-Easybee
                        et AP4-Easybee, où j'ai pu exploiter pleinement les
                        fonctionnalités de phpMyAdmin pour optimiser la gestion
                        de mes bases de données.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col"
                ontouchstart="this.classList.toggle('hover');"
              >
                <div className="containerCard">
                  <div
                    className="front card bot"
                    style={{ backgroundImage: `url(${qt})` }}
                  >
                    <div className="inner innerqt">
                      <p className="lang langqt">Qt Creator</p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="innertxt innertxtqt">
                      <p>
                        Pendant ma deuxième année de BTS SIO, j'ai exploré Qt
                        Creator, une plateforme qui m'a permis d'approfondir mes
                        compétences en C++. Son utilisation a été
                        particulièrement bénéfique dans la réalisation de mon
                        projet AP2-Easybee, présenté lors de mes épreuves de BTS
                        SIO. Qt Creator a ainsi joué un rôle clé dans le succès
                        de mon projet et dans mon apprentissage du langage C++.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Skills;
