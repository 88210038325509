import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faBars } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../css/App.css";
import "../css/index.css";
import "../css/Nav.css";
import "../css/Home.css";
import logo from "../Fichiers/logo.png";
import photo from "../Fichiers/Photo.png";
import CV from "../Fichiers/CV Nikola Legay.pdf";
library.add(faMoon, faLinkedin, faGithub, faBars);

function Home() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = CV;
    link.download = "CV Nikola Legay.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const darkbtn = document.getElementById("darkbtn");
    const body = document.body;
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";

    document.documentElement.style.overflow = "hidden";

    if (isDarkMode) {
      body.classList.add("dark-mode");
      darkbtn.checked = true;
    }

    darkbtn.addEventListener("change", () => {
      if (darkbtn.checked) {
        body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "disabled");
      }
    });

    window.addEventListener("load", () => {
      const moonIcon = document.querySelector(".moon-icon");
      moonIcon.style.opacity = "1";
      moonIcon.style.transition = "opacity 1s ease forwards";
    });
  }, []);

  return (
    <div className="App">
      <header className="container">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="logo-panda" />
            </a>
          </div>
          <input type="checkbox" id="click" />

          <label
            htmlFor="click"
            className="mainicon"
            onClick={toggleMobileMenu}
          >
            <div className="menu">
              <i className="bx bx-menu"></i>
            </div>
          </label>
          <span className="bars" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </span>
          <ul className={`navList ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <a href="/" className="active txtNav" style={{ "--navAni": 1 }}>
                Accueil
              </a>
            </li>
            <li>
              <a href="/aboutMe" className="txtNav" style={{ "--navAni": 2 }}>
                À propos de moi
              </a>
            </li>
            <li>
              <a href="/skills" className="txtNav" style={{ "--navAni": 3 }}>
                Skills
              </a>
            </li>
            <li>
              <a href="/projets" className="txtNav" style={{ "--navAni": 4 }}>
                Projets
              </a>
            </li>
            <li>
              <a href="/contact" className="txtNav" style={{ "--navAni": 5 }}>
                Contact
              </a>
            </li>
          </ul>
          <label className="mode">
            <input type="checkbox" id="darkbtn" />
            <span className="moon-icon">
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </label>
        </div>
      </header>

      <section className="container">
        <div className="main">
          <div className="social">
            {/* <a
              href="#"
              className={`social-btn linkedin-btn`}
              style={{ "--socialAni": 1 }}
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </a>
            <a
              href="#"
              className={`social-btn github-btn`}
              style={{ "--socialAni": 2 }}
            >
              <FontAwesomeIcon icon={["fab", "github"]} />
            </a> */}
          </div>

          <div className="detail">
            <h1>
              Bonjour, je m'appelle <br></br> <span>Nikola Legay</span>
            </h1>
            <h3 className="homeMediumTittle">Développeur web junior </h3>
            <p>
              Bonjour et bienvenue sur mon portfolio ! Je suis passionné par le
              développement web. Explorez mes projets créatifs et découvrez
              comment ma passion se traduit en réalisations concrètes. N'hésitez
              pas à me contacter pour discuter de collaborations passionnantes
              ou de nouvelles opportunités professionnelles.
            </p>
            <div className="btncv-social">
              <div className="btn">
                <button onClick={handleDownload}>Download CV</button>
              </div>
              <div className="social">
                <a
                  href="https://www.linkedin.com/in/nikola-legay-090b02283/"
                  target="_blank"
                  className={`social-btn linkedin-btn`}
                  style={{ "--socialAni": 1 }}
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
                <a
                  href="https://github.com/l-nikola"
                  target="_blank"
                  className={`social-btn github-btn`}
                  style={{ "--socialAni": 2 }}
                >
                  <FontAwesomeIcon icon={["fab", "github"]} />
                </a>
              </div>
            </div>
          </div>
          <div className="img-sec">
            <div className="images"></div>
            <img src={photo} alt="" className="img-w" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
