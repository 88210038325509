import React, { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faTrello } from "@fortawesome/free-brands-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../css/App.css";
import "../css/index.css";
import "../css/Nav.css";
import "../css/Projet.css";
import "../css/Home.css";
import logo from "../Fichiers/logo.png";
import easybee from "../Fichiers/project/easybee.png";
import ap21 from "../Fichiers/project/Ap2/ap2-1.png";
import ap22 from "../Fichiers/project/Ap2/ap2-2.png";
import ap23 from "../Fichiers/project/Ap2/ap2-3.png";
import ap24 from "../Fichiers/project/Ap2/ap2-4.png";
import ap25 from "../Fichiers/project/Ap2/ap2-5.png";
import ap26 from "../Fichiers/project/Ap2/ap2-6.png";
import ap27 from "../Fichiers/project/Ap2/ap2-7.png";
import ap28 from "../Fichiers/project/Ap2/ap2-8.png";
import ap29 from "../Fichiers/project/Ap2/ap2-9.png";
import ap210 from "../Fichiers/project/Ap2/ap2-10.png";
import ap211 from "../Fichiers/project/Ap2/ap2-11.png";
import ap41 from "../Fichiers/project/Ap4/ap4-1.png";
import ap42 from "../Fichiers/project/Ap4/ap4-2.png";
import ap44 from "../Fichiers/project/Ap4/ap4-4.png";
import ap45 from "../Fichiers/project/Ap4/ap4-5.png";
import ap46 from "../Fichiers/project/Ap4/ap4-6.png";
import ap47 from "../Fichiers/project/Ap4/ap4-7.png";
import ap48 from "../Fichiers/project/Ap4/ap4-8.png";
import ap49 from "../Fichiers/project/Ap4/ap4-9.png";
import ap410 from "../Fichiers/project/Ap4/ap4-10.png";
import ap411 from "../Fichiers/project/Ap4/ap4-11.png";
import ap412 from "../Fichiers/project/Ap4/ap4-12.png";
import ap413 from "../Fichiers/project/Ap4/ap4-13.png";
import ap414 from "../Fichiers/project/Ap4/ap4-14.png";
library.add(faMoon, faLinkedin, faGithub);
library.add(faTrello);
library.add(faXmark);

function Projets() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const [showPopupPort, setShowPopupPort] = useState(false);
  const [showPopupAp4, setShowPopupAp4] = useState(false);
  const [showPopupAp2, setShowPopupAp2] = useState(false);

  const togglePopupPort = () => {
    setShowPopupPort(!showPopupPort);
  };

  const togglePopupAp4 = () => {
    setShowPopupAp4(!showPopupAp4);
  };

  const togglePopupAp2 = () => {
    setShowPopupAp2(!showPopupAp2);
  };

  //Portfolio

  const [showLangDetailsPort, setShowLangDetailsPort] = useState(false);
  const [showOutilDetailsPort, setShowOutilDetailsPort] = useState(false);
  const [showPbDetailsPort, setShowPbDetailsPort] = useState(false);

  const toggleDetailslangPort = () => {
    // Ferme les autres éléments
    setShowOutilDetailsPort(false);
    setShowPbDetailsPort(false);
    // Ouvre ou ferme l'élément actuel
    setShowLangDetailsPort(!showLangDetailsPort);
  };

  const toggleDetailsoutilPort = () => {
    // Ferme les autres éléments
    setShowLangDetailsPort(false);
    setShowPbDetailsPort(false);
    // Ouvre ou ferme l'élément actuel
    setShowOutilDetailsPort(!showOutilDetailsPort);
  };

  const toggleDetailspbPort = () => {
    // Ferme les autres éléments
    setShowLangDetailsPort(false);
    setShowOutilDetailsPort(false);
    // Ouvre ou ferme l'élément actuel
    setShowPbDetailsPort(!showPbDetailsPort);
  };

  //Ap2

  const [showLangDetailsAp2, setShowLangDetailsAp2] = useState(false);
  const [showOutilDetailsAp2, setShowOutilDetailsAp2] = useState(false);
  const [showPbDetailsAp2, setShowPbDetailsAp2] = useState(false);

  const toggleDetailslangAp2 = () => {
    // Ferme les autres éléments
    setShowOutilDetailsAp2(false);
    setShowPbDetailsAp2(false);
    // Ouvre ou ferme l'élément actuel
    setShowLangDetailsAp2(!showLangDetailsAp2);
  };

  const toggleDetailsoutilAp2 = () => {
    // Ferme les autres éléments
    setShowLangDetailsAp2(false);
    setShowPbDetailsAp2(false);
    // Ouvre ou ferme l'élément actuel
    setShowOutilDetailsAp2(!showOutilDetailsAp2);
  };

  const toggleDetailspbAp2 = () => {
    // Ferme les autres éléments
    setShowLangDetailsAp2(false);
    setShowOutilDetailsAp2(false);
    // Ouvre ou ferme l'élément actuel
    setShowPbDetailsAp2(!showPbDetailsAp2);
  };

  //Ap4

  const [showLangDetailsAp4, setShowLangDetailsAp4] = useState(false);
  const [showOutilDetailsAp4, setShowOutilDetailsAp4] = useState(false);
  const [showPbDetailsAp4, setShowPbDetailsAp4] = useState(false);

  const toggleDetailslangAp4 = () => {
    // Ferme les autres éléments
    setShowOutilDetailsAp4(false);
    setShowPbDetailsAp4(false);
    // Ouvre ou ferme l'élément actuel
    setShowLangDetailsAp4(!showLangDetailsAp4);
  };

  const toggleDetailsoutilAp4 = () => {
    // Ferme les autres éléments
    setShowLangDetailsAp4(false);
    setShowPbDetailsAp4(false);
    // Ouvre ou ferme l'élément actuel
    setShowOutilDetailsAp4(!showOutilDetailsAp4);
  };

  const toggleDetailspbAp4 = () => {
    // Ferme les autres éléments
    setShowLangDetailsAp4(false);
    setShowOutilDetailsAp4(false);
    // Ouvre ou ferme l'élément actuel
    setShowPbDetailsAp4(!showPbDetailsAp4);
  };

  const handleMaquettesClick = () => {
    const lien = "https://whimsical.com/ap4-CAkCBkBp5hQvTsUsfJGB5Y";

    window.open(lien, "_blank");
  };

  const handleMaquettesClick2 = () => {
    const lien =
      "https://www.figma.com/file/6INEv2CpJmegdkqDfQiWMr/Portfolio?type=design&node-id=0%3A1&mode=design&t=ox6IydSdga3IF3AS-1";

    window.open(lien, "_blank");
  };

  useEffect(() => {
    const darkbtn = document.getElementById("darkbtn");
    const body = document.body;
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";

    if (isDarkMode) {
      body.classList.add("dark-mode");
      body.classList.remove("light-mode");
      darkbtn.checked = true;
    } else {
      body.classList.add("light-mode");
    }

    darkbtn.addEventListener("change", () => {
      if (darkbtn.checked) {
        body.classList.add("dark-mode");
        body.classList.remove("light-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        body.classList.remove("dark-mode");
        body.classList.add("light-mode");
        localStorage.setItem("darkMode", "disabled");
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="container">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="logo-panda" />
            </a>
          </div>
          <input type="checkbox" id="click" />

          <label
            htmlFor="click"
            className="mainicon"
            onClick={toggleMobileMenu}
          >
            <div className="menu">
              <i className="bx bx-menu"></i>
            </div>
          </label>
          <span className="bars" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars" />
          </span>
          <ul className={`navList ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <a href="/" style={{ "--navAni": 1 }}>
                Accueil
              </a>
            </li>
            <li>
              <a href="/aboutMe" style={{ "--navAni": 2 }}>
                À propos de moi
              </a>
            </li>
            <li>
              <a href="/skills" style={{ "--navAni": 3 }}>
                Skills
              </a>
            </li>
            <li>
              <a href="/projets" className="active" style={{ "--navAni": 4 }}>
                Projets
              </a>
            </li>
            <li>
              <a href="/contact" style={{ "--navAni": 5 }}>
                Contact
              </a>
            </li>
          </ul>
          <label className="mode">
            <input type="checkbox" id="darkbtn" />
            <span className="moon-icon">
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </label>
        </div>
      </header>

      {/* Portfolio */}

      <section className="container">
        <div className="mainproject">
          <div className="cardproject">
            <div className="overflowcard">
              <h2 className="largeTitre">Portfolio</h2>
              <div className="logoPortfolio">
                <img src={logo} alt="" className="logoPortfolio" />
              </div>
              <div className="espaceInCard">
                <div className="description">
                  <h3 className="descriptionTitle">Court descriptif :</h3>
                  <p>
                    Je me lance dans la création d'un nouveau portfolio avec
                    JavaScript et React pour actualiser mon site et approfondir
                    mes compétences en développement web. Cette refonte vise à
                    offrir une expérience utilisateur moderne et interactive,
                    tout en représentant au mieux mes compétences.
                  </p>
                </div>
              </div>
              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailslangPort}>
                  <h3>Langages et framework</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showLangDetailsPort ? "visible" : ""}`}
                >
                  <li className="listeproject">HTML</li>
                  <li className="listeproject">CSS</li>
                  <li className="listeproject">JavaScript</li>
                  <li className="listeproject">React</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailsoutilPort}>
                  <h3>Outils</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showOutilDetailsPort ? "visible" : ""}`}
                >
                  <li className="listeproject">Visual Studio Code</li>
                  <li className="listeproject">Figma</li>
                  <li className="listeproject">PhotoShop</li>
                  <li className="listeproject">Git</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailspbPort}>
                  <h3>Problèmes rencontrés</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showPbDetailsPort ? "visible" : ""}`}
                >
                  <li className="listeproject">
                    Aucune connaissance dans React et JavaScript
                  </li>
                  <li className="listeproject">
                    Le changement entre le light et dark mode
                  </li>
                  <li className="listeproject">
                    Les routes que j'ai eu beaucoup de mal à comprendre
                  </li>
                  <li className="listeproject">
                    Réussir à faire fonctionner le formulaire de contact
                  </li>
                  <li className="listeproject">Faire la partie responsive</li>
                </div>
              </div>

              <div className="espaceInCard">
                <h3 className="lienSpace">Liens :</h3>
                <div className="detailCardPort">
                  <div className="btnPort">
                    <button
                      className="btnPlusDeDetail"
                      onClick={togglePopupPort}
                    >
                      Plus de détails
                    </button>
                  </div>
                </div>
                {showPopupPort && (
                  <div className="popup visible">
                    {/* Contenu de la pop-up */}
                    <div className="popupContent">
                      <h1 className="projectName projectNamePort">Portfolio</h1>
                      <div className="btnFermer">
                        <a onClick={togglePopupPort}>
                          <FontAwesomeIcon icon={faXmark} />
                        </a>
                      </div>
                    </div>
                    <h1>1. Synopsis</h1>
                    <p className="texteprojets">
                      Dans ce projet, je me lance dans la refonte de mon
                      portfolio en utilisant JavaScript et React pour créer une
                      expérience utilisateur moderne et interactive. L'objectif
                      est de mettre en avant mes compétences en développement
                      web à travers une interface esthétique, réactive et
                      engageante. En utilisant React, je prévois d'optimiser la
                      navigation, d'ajouter des éléments interactifs et de
                      présenter de manière claire mes compétences et projets. Le
                      projet comprend également des aspects de personnalisation
                      pour faciliter les mises à jour futures. Mon approche
                      inclut une phase de planification, de développement, de
                      tests et de déploiement, avec une attention continue à la
                      maintenance pour assurer la performance du site. Ce
                      portfolio repensé servira de vitrine professionnelle,
                      démontrant mes compétences en tant que développeur web.
                    </p>
                    <h1>2. Préparation de l’application</h1>
                    <p className="texteprojets">
                      Après avoir réalisé l'installation de React et vu le
                      fonctionnement de JavaScript, j'ai entamé la préparation
                      de mon application. Cela a inclus le choix d'un hébergeur
                      web, l'initialisation d'un dépôt Git, et la création de
                      maquettes pour guider le développement visuel du
                      portfolio. Ces étapes cruciales fournissent une base
                      solide pour la phase de développement.
                    </p>
                    <div className="btn">
                      <button onClick={handleMaquettesClick2}>Maquettes</button>
                    </div>
                    <h1>3. Développement de l’application</h1>
                    <p className="texteprojets">
                      Dans cette phase, je me suis plongé dans le processus de
                      création de mon portfolio en utilisant React et
                      JavaScript. J'ai mis en œuvre les maquettes élaborées lors
                      de la phase de préparation, en accordant une attention
                      particulière à l'optimisation de l'interface utilisateur,
                      à la réactivité et à l'ajout d'éléments interactifs. Le
                      développement a également inclus la gestion dynamique des
                      données, la création de composants réutilisables, et
                      l'intégration de fonctionnalités pour mettre en valeur mes
                      compétences et projets de manière convaincante. Tout au
                      long de cette étape, j'ai veillé à maintenir une cohérence
                      visuelle et à garantir une expérience utilisateur fluide
                      et engageante.
                    </p>
                    <h1>4. Test de l’application</h1>
                    <p className="texteprojets">
                      La phase de test a constitué une étape cruciale pour
                      assurer la qualité et la robustesse de mon portfolio. J'ai
                      minutieusement vérifié chaque aspect de l'application, en
                      m'assurant que tous les boutons fonctionnaient
                      correctement et dirigeaient les utilisateurs vers les
                      destinations prévues. Un examen approfondi du style a été
                      effectué pour détecter tout problème éventuel, avec une
                      attention particulière portée aux animations pour garantir
                      une expérience visuelle harmonieuse.
                      <br></br>
                      <br></br>
                      J'ai consacré des efforts à rendre le site aussi
                      responsive que possible avec mes compétences actuelles.
                      Des essais ont été réalisés sur une variété d'appareils et
                      de tailles d'écrans pour assurer une expérience
                      utilisateur optimale, quel que soit le dispositif utilisé.
                      L'objectif était de créer un portfolio qui s'adapte de
                      manière fluide à différents environnements, offrant ainsi
                      une accessibilité maximale.
                      <br></br>
                      <br></br>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* AP4 */}

          <div className="cardproject">
            <div className="overflowcard">
              <h2 className="largeTitre">AP4-Easybee</h2>
              <div className="imgeasybee">
                <img src={easybee} alt="" className="easybee" />
              </div>
              <div className="espaceInCard">
                <div className="description">
                  <h3 className="descriptionTitle">Court descriptif :</h3>
                  <p>
                    Auguste Klin veut faciliter la collecte d'essaims pour
                    promouvoir l'apiculture. Il prépare le lancement d'une
                    application gratuite où les utilisateurs signalent un essaim
                    via la géolocalisation. Les apiculteurs inscrits reçoivent
                    une alerte avec l'emplacement précis. Le premier à cliquer
                    sur « je prends en charge » a 3 heures pour le récupérer,
                    sinon les autres sont notifiés.
                  </p>
                </div>
              </div>
              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailslangAp4}>
                  <h3>Langages et framework</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showLangDetailsAp4 ? "visible" : ""}`}
                >
                  <li className="listeproject">Java</li>
                  <li className="listeproject">PHP</li>
                  <li className="listeproject">SQL</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailsoutilAp4}>
                  <h3>Outils</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showOutilDetailsAp4 ? "visible" : ""}`}
                >
                  <li className="listeproject">Android Studio</li>
                  <li className="listeproject">Visual Studio Code</li>
                  <li className="listeproject">Trello</li>
                  <li className="listeproject">Whimsical</li>
                  <li className="listeproject">phpMyAdmin</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailspbAp4}>
                  <h3>Problèmes rencontrés</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div className={`deroule ${showPbDetailsAp4 ? "visible" : ""}`}>
                  <li className="listeproject">
                    Nous n'avons pas réussi a mettre en place l'API Google Maps
                  </li>
                  <li className="listeproject">
                    La liaison entre le PHP et le Java
                  </li>
                </div>
              </div>

              <div className="espaceInCard">
                <h3 className="lienSpace">Liens :</h3>
                <div className="detailCard">
                  <div className="btn btnPlusDeDetail">
                    <button onClick={togglePopupAp4}>Plus de détails</button>
                  </div>
                  <div className="btnGitTrello">
                    <div className="btnGit">
                      <a
                        href="https://github.com/l-nikola/Java-AP4-Easybee"
                        target="_blank"
                        className={`social-btn github-btn`}
                        style={{ "--socialAni": 2 }}
                      >
                        <FontAwesomeIcon icon={["fab", "github"]} />
                      </a>
                    </div>
                    <div className="btnTrello">
                      <a
                        href="https://trello.com/b/qjx0ex9U/mission-4"
                        target="_blank"
                        className={`social-btn trello-btn`}
                        style={{ "--socialAni": 2 }}
                      >
                        <FontAwesomeIcon icon={["fab", "trello"]} />
                      </a>
                    </div>
                  </div>
                </div>
                {showPopupAp4 && (
                  <div className="popup visible">
                    {/* Contenu de la pop-up */}
                    <div className="popupContent">
                      <h1 className="projectName">AP4-Easybee</h1>
                      <div className="btnFermer">
                        <a onClick={togglePopupAp4}>
                          <FontAwesomeIcon icon={faXmark} />
                        </a>
                      </div>
                    </div>
                    <h1>1. Synopsis</h1>
                    <p className="texteprojets">
                      Auguste Klin veut promouvoir l’apiculture et faciliter la
                      collecte des essaims dans le cadre de son projet de
                      développement. Il prévoit de lancer une application
                      gratuite au nom de son entreprise. Les utilisateurs
                      peuvent signaler la présence d’un essaim via l’application
                      en activant la géolocalisation. Les apiculteurs inscrits
                      et ayant activé les notifications reçoivent une alerte
                      avec l’emplacement précis de l’essaim. Le premier
                      apiculteur qui clique sur « je prends en charge » est
                      responsable de sa récupération dans les 3 heures. S’il ne
                      le fait pas, une nouvelle notification est envoyée aux
                      autres apiculteurs.Auguste Klin veut promouvoir
                      l’apiculture et faciliter la collecte des essaims dans le
                      cadre de son projet de développement. Il prévoit de lancer
                      une application gratuite au nom de son entreprise. Les
                      utilisateurs peuvent signaler la présence d’un essaim via
                      l’application en activant la géolocalisation. Les
                      apiculteurs inscrits et ayant activé les notifications
                      reçoivent une alerte avec l’emplacement précis de
                      l’essaim. Le premier apiculteur qui clique sur « je prends
                      en charge » est responsable de sa récupération dans les 3
                      heures. S’il ne le fait pas, une nouvelle notification est
                      envoyée aux autres apiculteurs.
                    </p>
                    <h1>2. Préparation de l’application</h1>
                    <p className="texteprojets">
                      En analysant le contexte et les spécifications, il est
                      clair qu’il est nécessaire de créer une application
                      Android. Celle-ci aura pour but de gérer les signalements
                      d’essaims faits par des particuliers et de permettre aux
                      apiculteurs d’utiliser la localisation fournie pour
                      récupérer l’essaim dans un délai de 3 heures.
                      <br></br>
                      <br></br>
                      Pour ce faire, un diagramme des cas d’utilisation a été
                      réalisé :
                    </p>
                    <img src={ap41} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      Un modèle entité association du processus et son modèle
                      relationnel associé ont également été réalisés :
                    </p>
                    <img src={ap42} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      Et enfin, les maquettes ont été réalisés pour modéliser
                      l’application :
                    </p>
                    <div className="btn">
                      <button onClick={handleMaquettesClick}>Maquettes</button>
                    </div>
                    <h1>3. Développement de l’application</h1>
                    <p className="texteprojets">
                      Nous allons créer l’application sur Android Studio, qui
                      inclura une base de données hébergée sur le serveur du
                      groupe SISR, avec son adresse IP comme point de connexion.
                      Les fichiers PHP contenant différentes requêtes SQL seront
                      intégrés pour assurer la gestion des données.
                      <br></br>
                      <br></br>
                      La page d’accueil de l’application comportera uniquement
                      deux boutons. Le premier, dédié aux particuliers,
                      permettra à l’utilisateur d’entrer son nom, prénom, et
                      d’accepter la géolocalisation. Une fois validé,
                      l’application insérera ces informations dans la table «
                      essaim ».
                    </p>
                    <img src={ap44} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Cependant, si le particulier n’est pas présent pour
                      signaler un essaim, il peut consulter la liste des essaims
                      qu’il a déclarés en utilisant son nom et prénom. Il aura
                      la possibilité de supprimer un essaim ou de confirmer la
                      récupération d’un essaim.
                    </p>
                    <img src={ap45} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      D’un autre côté, le bouton « apiculteur » permettra à
                      l’apiculteur de s’authentifier. Si l’identifiant et le mot
                      de passe correspondent à une entrée dans la table «
                      apiculteur », l’application enregistrera l’ID de
                      l’apiculteur (id_apiculteur) et le statut « essaimEnCharge
                      ». La valeur de cette dernière colonne déterminera la page
                      vers laquelle l’apiculteur sera dirigé. En effet, si «
                      essaimEnCharge » est NULL, cela signifie que l’apiculteur
                      n’a pas encore sélectionné un essaim à récupérer, donc il
                      sera redirigé vers la page « essaimView ». Sinon, il sera
                      dirigé vers la page « essaimEnCharge » avec la valeur de
                      l’essaim auquel il est associé.
                    </p>
                    <img src={ap46} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Dans le cas où l’apiculteur n’est pas enregistré dans
                      l’application, il aura la possibilité de s’inscrire pour
                      ajouter ses informations à la table « apiculteur ».
                    </p>
                    <img src={ap47} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Lorsque l’apiculteur accède à la page « essaimView », il
                      peut appuyer sur un bouton qui affiche la liste de tous
                      les essaims. En saisissant l’ID de l’essaim et en appuyant
                      sur le deuxième bouton, l’apiculteur s’engage à récupérer
                      l’essaim dans les 3 heures. Il sera ensuite redirigé vers
                      la page « essaimEnCharge » avec l’essaim qu’il a pris en
                      charge.
                    </p>
                    <img src={ap48} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Lorsque l’apiculteur accède à la page « essaimEnCharge »,
                      l’écran affiche le temps restant pour la récupération de
                      l’essaim (3 heures), la localisation de l’essaim et le nom
                      de la personne concernée. L’apiculteur a la possibilité
                      d’abandonner la récupération de l’essaim à tout moment.
                      Cependant, pour qu’un essaim soit officiellement déclaré
                      comme récupéré, la validation doit être effectuée par le
                      particulier associé à cet essaim.
                    </p>
                    <img src={ap49} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Pour gérer le timer, nous avons créé une méthode qui
                      exécute la création et le gestionnaire du timer après que
                      la méthode postExecute de backgroundWorkerSetTimer.java
                      soit totalement exécutée.
                    </p>
                    <h1>4. Test de l’application</h1>
                    <p className="texteprojets">
                      Une fois que nous avions transféré la base de données et
                      le code PHP sur le serveur créé par le groupe SISR, en
                      plus d’avoir ajusté tous les String URL de l’application
                      Android avec le domaine du serveur, nous avons été pris au
                      dépourvu en découvrant un problème lié au fuseau horaire.
                      <br></br>
                      <br></br>
                      En effet, lorsqu’un apiculteur devait se rendre pour
                      récupérer l’essaim, le délai était réduit à une heure au
                      lieu de trois. Pour remédier à ce problème, nous avons
                      simplement ajouté la ligne suivante dans le fichier
                      takeCharge.php :<br></br>
                      date_default_timezone_set('Europe/Paris');
                    </p>
                    <img src={ap410} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      Un autre problème s’est manifesté : lorsqu’un apiculteur
                      prenait en charge un essaim, les valeurs de latitude et
                      longitude étaient nulles. Cependant, après avoir quitté
                      l’application puis y être revenu, ces valeurs étaient
                      récupérées.
                      <br></br>
                      <br></br>
                      En examinant le fichier setTimer.php, nous avons réalisé
                      que le problème était lié au code Android, car la variable
                      essaimEnCharge provenait de la page d’authentification. À
                      ce stade, essaimEnCharge est null car l’apiculteur n’a pas
                      encore choisi son essaim. Cependant, lors de la deuxième
                      connexion, après avoir sélectionné un essaim, la valeur
                      n’était plus null, car le changement de valeur null
                      s’opérait entre la page d’authentification et la page de
                      gestion de l’essaim choisi. Ainsi, nous avons créé une
                      variable publique static String essaimEnCharge dans le
                      constructeur de l’application, qui récupère la valeur
                      choisie entre les deux pages mentionnées précédemment. En
                      utilisant une condition « if », l’application reçoit
                      désormais une valeur non nulle et précise pour
                      essaimEnCharge lors de la première connexion et des
                      connexions ultérieures.
                    </p>
                    <img src={ap411} alt="" className="imgTelAp4" />
                    <p className="texteprojets">
                      Maintenant que les problèmes de parcours ont été résolus,
                      vérifions si toutes les fonctionnalités de l’application
                      sont opérationnelles. Après plusieurs essais effectués,
                      l’application est maintenant fonctionnelle pour tout
                      utilisateur sur Android.
                    </p>
                    <img src={ap412} alt="" className="imgMargTop" />
                    <img src={ap413} alt="" />
                    <img src={ap414} alt="" className="imgMargBot" />
                    <p className="texteprojets">
                      Maintenant que tous les problèmes de l’application ont été
                      résolus et que l’ensemble du cahier des charges a été
                      respecté, nous sommes prêts à déployer l’APK. Vous pouvez
                      le trouver sur Github en cliquant sur le bouton
                      ci-dessous, et nous allons héberger le code PHP et la base
                      de données sur le serveur du groupe SISR.
                    </p>
                    <div className="btnPopup">
                      <div className="btnGitIn">
                        <a
                          href="https://github.com/l-nikola/Java-AP4-Easybee"
                          target="_blank"
                          className={`social-btn github-btn`}
                          style={{ "--socialAni": 2 }}
                        >
                          <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                      </div>
                      <div className="btnTrelloIn">
                        <a
                          href="https://trello.com/b/qjx0ex9U/mission-4"
                          target="_blank"
                          className={`social-btn trello-btn`}
                          style={{ "--socialAni": 2 }}
                        >
                          <FontAwesomeIcon icon={["fab", "trello"]} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* AP2 */}

          <div className="cardproject">
            <div className="overflowcard">
              <h2 className="largeTitre">AP2-Easybee</h2>
              <div className="imgeasybee">
                <img src={easybee} alt="" className="easybee" />
              </div>
              <div className="espaceInCard">
                <div className="description">
                  <h3 className="descriptionTitle">Court descriptif :</h3>
                  <p>
                    Auguste Klin a acquis un entrepôt de 2 000 m2, à 2 km du
                    magasin, pour augmenter la capacité de stockage. Le magasin
                    conservera une réserve, adaptée pour héberger les serveurs
                    informatiques. Une nouvelle application sera développée pour
                    gérer le stock en temps réel, couvrant l'entrepôt et le
                    magasin (y compris la réserve) pour chaque produit.
                  </p>
                </div>
              </div>
              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailslangAp2}>
                  <h3>Langages et framework</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showLangDetailsAp2 ? "visible" : ""}`}
                >
                  <li className="listeproject">C++</li>
                  <li className="listeproject">SQL</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailsoutilAp2}>
                  <h3>Outils</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div
                  className={`deroule ${showOutilDetailsAp2 ? "visible" : ""}`}
                >
                  <li className="listeproject">Qt Creator</li>
                  <li className="listeproject">Trello</li>
                  <li className="listeproject">Whimsical</li>
                  <li className="listeproject">phpMyAdmin</li>
                </div>
              </div>

              <div className="espaceInCard">
                <div className="smallTitre" onClick={toggleDetailspbAp2}>
                  <h3>Problèmes rencontrés</h3>
                  <button className="btnDeroule">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <div className={`deroule ${showPbDetailsAp2 ? "visible" : ""}`}>
                  <li className="listeproject">
                    Passage des données entre les différents utilisateurs
                  </li>
                  <li className="listeproject">
                    La liaison entre l'application et la base de donnée
                  </li>
                </div>
              </div>

              <div className="espaceInCard">
                <h3 className="lienSpace">Liens :</h3>
                <div className="detailCard">
                  <div className="btn btnPlusDeDetail">
                    <button onClick={togglePopupAp2}>Plus de détails</button>
                  </div>
                  <div className="btnGitTrello">
                    <div className="btnGit">
                      <a
                        href="https://github.com/l-nikola/CPlusPlus-AP2-Easybee"
                        target="_blank"
                        className={`social-btn github-btn`}
                        style={{ "--socialAni": 2 }}
                      >
                        <FontAwesomeIcon icon={["fab", "github"]} />
                      </a>
                    </div>
                    <div className="btnTrello">
                      <a
                        href="https://trello.com/b/dVYX2aRQ/easy-bee-mission-2"
                        target="_blank"
                        className={`social-btn trello-btn`}
                        style={{ "--socialAni": 2 }}
                      >
                        <FontAwesomeIcon icon={["fab", "trello"]} />
                      </a>
                    </div>
                  </div>
                </div>
                {showPopupAp2 && (
                  <div className="popup visible">
                    {/* Contenu de la pop-up */}
                    <div className="popupContent">
                      <h1 className="projectName">AP2-Easybee</h1>
                      <div className="btnFermer">
                        <a onClick={togglePopupAp2}>
                          <FontAwesomeIcon icon={faXmark} />
                        </a>
                      </div>
                    </div>
                    <h1>1. Synopsis</h1>
                    <p className="texteprojets">
                      Dans le cadre de son projet de développement, Auguste Klin
                      a fait l’acquisition d’un entrepôt de 2 000 m2 situé à 2
                      kms du magasin afin de stocker plus de produits et
                      répondre ainsi à la demande de la clientèle. Le magasin
                      disposera toujours d’une réserve et une partie de celle-ci
                      sera aménagée pour recevoir les serveurs nécessaires au
                      développement informatique. Auguste Klin a décidé de
                      mettre en place une nouvelle application qui doit
                      permettre de gérer le stock en temps réel : stock en
                      entrepôt et stock en magasin (magasin plus la réserve)
                      pour chacun des produits.
                    </p>
                    <h1>2. Préparation de l’application</h1>
                    <p className="texteprojets">
                      En lisant le contexte et le cahier des charges, nous avons
                      compris qu’il faudra développer une application en C++
                      pour gérer le stock en temps réel de 3 endroits différents
                      : l’entrepôt, la réserve et le magasin. De plus,
                      l’application concernera 3 types d’utilisateurs : le
                      gérant, le réceptionniste de l’entrepôt et l’assistante de
                      direction.
                      <br></br>
                      <br></br>
                      Pour chaque stock, nous avons défini une quantité minimale
                      pour chaque produit afin de minimiser les risques d’une
                      rupture de stock de produit(s). S’il y a moins de produits
                      en stock en magasin, on en prendra dans la réserve. Si la
                      réserve n’a pas assez de produits, alors on utilisera le
                      stock de l’entrepôt.
                      <br></br>
                      <br></br>
                      Et si le stock en entrepôt est insuffisant, voire vide,
                      que faire ? C’est là que notre application entre en jeu.
                      Chaque matin, l’assistante utilise l’application pour
                      vérifier le stock de chaque produit dans l’entrepôt. En
                      cas de manque ou d’insuffisance, elle peut automatiquement
                      rédiger une commande, envoyée ensuite au fournisseur pour
                      remplir partiellement ou totalement la demande.
                      <br></br>
                      <br></br>
                      Peu importe le niveau de remplissage des produits, le
                      réceptionniste peut compléter l’entrepôt à tout moment
                      avec la commande pour réagir rapidement à une éventuelle
                      rupture de stock. L’assistante peut ensuite procéder au
                      paiement une fois la commande complète, puis la
                      transmettre au gérant pour finalisation.
                      <br></br>
                      <br></br>
                      En résumé des rôles des utilisateurs, nous avons élaboré
                      un cas d’utilisation et initié la construction des tables
                      produit, catégorie, commande, utilisateur, et type
                      d’utilisateur.
                    </p>
                    <img src={ap21} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      En utilisant une liste de produits Excel, nous avons créé
                      un modèle de table produit et l’avons rempli via un «
                      insert into ». En parallèle, la table catégorie a été
                      complétée. Pour gérer les utilisateurs, nous avons établi
                      une table type utilisateur pour les 3 rôles, accompagnée
                      d’une table utilisateur avec identifiant et mot de passe,
                      assurant ainsi la sécurité de l’application et évitant les
                      interférences entre utilisateurs de rôles différents.
                      <br></br>
                      <br></br>
                      Ensuite, nous avons créé les tables etatCommande, commande
                      et fournisseur. Les fournisseurs, en tant que partenaires
                      d’EasyBee, n’ont pas accès à l’application. Leur présence
                      est répertoriée dans une table, associée à des produits en
                      cas de demande. La table commande, au lieu d’inclure
                      directement tous les détails, contient un identifiant, le
                      produit, la quantité, le coût total et le créateur de la
                      commande. J’ai suggéré la création de la table
                      detailCommande pour enregistrer les produits, quantités et
                      créateurs spécifiques de chaque ligne de commande, offrant
                      ainsi traçabilité et conformité aux principes de sécurité
                      informatique. Cela permet également de créer plusieurs
                      lignes, représentant plusieurs produits, dans une seule
                      commande.
                    </p>
                    <img src={ap22} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      Enfin, nous avons conçu des maquettes pour chaque page à
                      développer, suivi d’une vérification des éléments
                      potentiellement modifiables dans la base de données. Avec
                      tous les éléments conformes au cahier des charges, la
                      prochaine étape consiste simplement à passer au
                      développement.
                    </p>
                    <img src={ap23} alt="" className="imgPopup" />
                    <h1>3. Développement de l’application</h1>
                    <p className="texteprojets">
                      L’application en cours de développement sur QT Creator 8.0
                      Community est interconnectée avec la base de données. Pour
                      toutes les opérations, une requête telle que « select »
                      est incontournable. Afin de prévenir les connexions et
                      déconnexions excessives, un fichier header « initdb.h » a
                      été créé pour établir une connexion à la base de données
                      avec QT Creator, qui restera active jusqu’à la fermeture
                      de l’application.
                    </p>
                    <img src={ap24} alt="" className="imgPopup" />
                    <p className="texteprojets">
                      Ensuite, passons à l’authentification. Elle vérifie
                      simplement si l’identifiant et le mot de passe
                      correspondent à un utilisateur. En cas de correspondance,
                      elle identifie le type d’utilisateur et le redirige vers
                      la page correspondant à son rôle.
                    </p>
                    <img src={ap25} alt="" className="imgScreenAp2" />
                    <p className="texteprojets">
                      Si l’utilisateur est « gérant », alors il sera sur la page
                      ci-dessous. La table view affichera seulement les
                      commandes dont leur état sera « en attente de paiement ».
                      Le gérant pourra alors choisir une ou plusieurs commande à
                      valider, et ses commandes passeront par une update à «
                      commande clôturée » et le paiement sera effectué.
                    </p>
                    <img src={ap26} alt="" className="imgScreenAp2" />
                    <p className="texteprojets">
                      Si l’utilisateur est « assistante », alors il sera dans un
                      contrôleur pour choisir si il veut :
                    </p>
                    <h4>
                      Regarder si un produit est en rupture de stock ou
                      inférieur au stock minimum :
                    </h4>
                    <p className="texteprojets">
                      La table view va afficher seulement les produits qui
                      répondent à ce critère, l’assistante pourra sélectionner
                      une seule ligne et créer une commande qui prendra son
                      identifiant, l’identifiant du produit et la date de
                      création pour une bonne traçabilité.
                    </p>
                    <img src={ap27} alt="" className="imgScreenAp2" />
                    <h4>
                      Mettre en paiement la commande et envoyer la facture au
                      gérant :
                    </h4>
                    <p className="texteprojets">
                      La table view va afficher seulement les commandes à l’état
                      « réception totale », l’assistante pourra sélectionner une
                      ou plusieurs lignes et à l’aide d’un update, va changer
                      l’état de la commande à « en attente de paiement » pour
                      qu’elle apparaisse sur la table view du gérant.
                    </p>
                    <img src={ap28} alt="" className="imgScreenAp2" />
                    <p className="texteprojets">
                      Si l’utilisateur est « réceptionniste », alors il sera
                      dans une page pour réapprovisionner les stocks magasin et
                      réserve des produits stockés en entrepôt à l’aide d’une
                      table view si un produit en magasin ou en réserve se
                      retrouvait vide ou inférieur au stock minimum. Le
                      réceptionniste ne pourra sélectionner qu’un produit et
                      remplir x quantité pour stock magasin et/ou y quantité
                      pour stock réserve dont le total sera retiré du stock
                      entrepôt, à condition que le total soit inférieur ou égal
                      au stock entrepôt du produit.
                    </p>
                    <img src={ap29} alt="" className="imgPopup" />
                    <h1>4. Test de l’application</h1>
                    <p className="texteprojets">
                      Maintenant que le code de l’application est terminé, il
                      est temps de procéder à divers tests pour s’assurer
                      qu’elle est conforme aux exigences spécifiées dans le
                      cahier des charges d’EasyBee.
                    </p>
                    <img src={ap210} alt="" className="imgMargTop" />
                    <img src={ap211} alt="" className="imgMargBot" />
                    <p className="texteprojets">
                      Par conséquent, avec des résultats de test favorables
                      quant aux fonctionnalités requises, nous sommes prêts à
                      transférer la base de données au groupe SISR pour
                      l’hébergement, permettant ainsi que l’exécutable soit
                      opérationnel pour les utilisateurs de l’application.
                    </p>
                    <div className="btnPopup">
                      <div className="btnGitIn">
                        <a
                          href="https://github.com/l-nikola/CPlusPlus-AP2-Easybee"
                          target="_blank"
                          className={`social-btn github-btn`}
                          style={{ "--socialAni": 2 }}
                        >
                          <FontAwesomeIcon icon={["fab", "github"]} />
                        </a>
                      </div>
                      <div className="btnTrelloIn">
                        <a
                          href="https://trello.com/b/dVYX2aRQ/easy-bee-mission-2"
                          target="_blank"
                          className={`social-btn trello-btn`}
                          style={{ "--socialAni": 2 }}
                        >
                          <FontAwesomeIcon icon={["fab", "trello"]} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projets;
